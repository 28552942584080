import React from 'react';
import axios from 'axios';
import Logo from '../greaterLifeRevisionBLACK.png'
import HeroImg from '../greaterlife.jpg'
import emailjs from 'emailjs-com';

emailjs.init("user_XKcGifQrdfSZXE8g45PIY");
export default class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            lastname: '',
            title: '',
            company: '',
            email: '',
            phone: '',
            employees: '',
            grades: [],
            total: 0,
            grade1: '',
            grade2: '',
            grade3: '',
            grade4: '',
            grade5: '',
            grade6: '',
            grade7: '',
            grade8: '',
            q1: 0,
            q2: 0,
            q3: 0,
            q4: 0,
            q5: 0,
            q6: 0,
            q7: 0,
            q8: 0,
            q9: 0,
            q10: 0,
            q11: 0,
            q12: 0,
            q13: 0,
            q15: 0,
            q16: 0,
            q17: 0,
            q18: 0,
            q19: 0,
            q20: 0,
            q21: 0,
            q22: 0,
            q23: 0,
            q24: 0,
            q25: 0,
            q26: 0,
            q27: 0,
            q28: 0,
            q29: 0,
            q30: 0,
            q31: 0,
            q32: 0,
            q33: 0,
            q34: 0,
            q35: 0,
            q36: 0,
            q37: 0,
            q38: 0,
            q39: 0,
            q41: 0,
            q42: 0,
            q43: 0,
            q44: 0,
            q45: 0,
            q46: 0,
            q47: 0,
            q48: 0,
            q49: 0,
            q50: 0,
            q51: 0,
            q52: 0,
            q53: 0,
            q54: 0,
            q55: 0,
            q56: 0,
            q57: 0,
            q58: 0,
            q59: 0,
            q60: 0,
            q61: 0,

        };
    }
    buttonClick = (event) => {
        event.preventDefault();
        let buttonID = event.target.id;
        let array = document.querySelectorAll('.q');
        console.log('below')
        console.log(array)
        let question = "";
        let response = "";
        // when adding weights to certain grades this will need to change
        // move each condional up one if adding point to id
        if (event.target.id.length == 3) {
            // these wont change
            question = event.target.id[2];

            response = event.target.id[0];

            question = 'q' + question;
        } else if (event.target.id.length == 4) {
            // these wont change

            question = event.target.id[2] + event.target.id[3];
            response = event.target.id[0];

            question = 'q' + question;
        }

        this.setState({ [question]: response }, () => {
            {
                // console.log(this.state)
            }
        })

    }
    submit1 = (event) => {
        event.preventDefault();
        let { q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q37, q38, q39, q40, q41, q42, q43, q44, q45, q46, q47, q48, q49, q50, q51, q52, q53, q54, q55, q56, q57, q58, q59, q60, q61, employees } = this.state;
        let sec1 = [q1, q2, q3, q4, q5, q6]
        let total = 0;
        let grades = [];
        sec1.map(e => {
            total += parseInt(e)
        })
        console.log(total)
        let tt = document.querySelectorAll(".gradeSec")
        if (total >= 34) {
            grades.push("Excellent. You have built a very solid foundation that will deliver business impact.")
            tt[0].classList.add("great")
        } else if (total >= 30 && total <= 34) {
            tt[0].classList.add("great")
            grades.push("Good work! Make sure you keep the strategy updated when unexpected risks and opportunities come along.")
        } else if (total >= 26 && total <= 29) {
            tt[0].classList.add("mediocre")
            grades.push("Just average. Fix the gaps and it will help you prove how much value is being delivered by the communications department.")
        } else if (total <= 25) {
            tt[0].classList.add("bad")
            grades.push("You have opportunity to build a better strategy, measurement and reporting arrangement!")
        }
        console.log(grades)
        let grade1 = grades[0];
        this.setState({ grade1 }, () => {
            // console.log(this.state);
        });
    }
    submit2 = (event) => {
        event.preventDefault();
        let tt = document.querySelectorAll(".gradeSec")
        let { q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q37, q38, q39, q40, q41, q42, q43, q44, q45, q46, q47, q48, q49, q50, q51, q52, q53, q54, q55, q56, q57, q58, q59, q60, q61, employees } = this.state;
        let sec2 = [q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20]
        let total = 0;
        let grades = [];
        sec2.map(e => {
            total += parseInt(e)
        })
        if (total >= 56) {
            grades.push("Congratulations! Your communications team is well positioned in your organization, and you are positioned to earn high impact.")
            tt[1].classList.add("great")
        } else if (total >= 50 && total <= 55) {
            tt[1].classList.add("great")
            grades.push("Overall, great work. It shouldn’t be too difficult to fix the gaps and set up your communications to work wonders for your business goals.")
        } else if (total >= 43 && total <= 54) {
            tt[1].classList.add("mediocre")
            grades.push("It appears that your communications function is average, but not best practice. Give your communications team the best possible chance to succeed. It begins with proper org structure and reporting relationships, expecting them to provide valuable intelligence about your stakeholders, and making sure they are participating in business deliberations.")
        } else if (total <= 42) {
            tt[1].classList.add("bad")

            grades.push(" It’s worth your time to adjust your communications scope, role and org structure. Research shows that a poor communication function can cost a business the equivalent of one day’s salary for every employee… every week! ")
        }

        let grade2 = grades[0];
        this.setState({ grade2 })
    }
    submit3 = (event) => {
        event.preventDefault();
        let tt = document.querySelectorAll(".gradeSec")
        let { q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q37, q38, q39, q40, q41, q42, q43, q44, q45, q46, q47, q48, q49, q50, q51, q52, q53, q54, q55, q56, q57, q58, q59, q60, q61, employees } = this.state;
        let sec3 = [q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32]
        let total = 0;
        let grades = [];
        sec3.map(e => {
            total += parseInt(e)
        })
        if (total >= 58) {
            tt[2].classList.add("great")

            grades.push("Fantastic work! It seems that you have a strong chief communicator with a team of skilled, motivated people who are focused on priorities and motivated to grow.")
        } else if (total >= 51 && total <= 57) {
            tt[2].classList.add("great")

            grades.push("Not bad. Share this assessment with your chief communicator and discuss the gaps. Soon you will be in the excellent zone.")
        } else if (total >= 45 && total <= 50) {
            tt[2].classList.add("mediocre")

            grades.push("Average is just not good enough. Make sure your chief communicator is getting good support to address the weaknesses.")
        } else if (total <= 44) {
            tt[2].classList.add("bad")

            grades.push("It appears you have room to improve how you use your people to establish a communications department that will deliver maximum impact for the business. Usually this requires heavy involvement from the HR team.")
        }
        let grade3 = grades[0];
        this.setState({ grade3 })
    }
    submit4 = (event) => {
        event.preventDefault();
        let tt = document.querySelectorAll(".gradeSec")
        let { q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q37, q38, q39, q40, q41, q42, q43, q44, q45, q46, q47, q48, q49, q50, q51, q52, q53, q54, q55, q56, q57, q58, q59, q60, q61, employees } = this.state;
        let sec4 = [q33, q34, q35, q36, q37, q38]
        let total = 0;
        let grades = [];
        sec4.map(e => {
            total += parseInt(e)
        })
        if (total >= 31) {
            tt[3].classList.add("great")

            grades.push("Big congratulations! Excellence in communication begins internally, and it appears that you have made the right communication moves to keep your staff informed and motivated. ")
        } else if (total >= 27 && total <= 30) {
            tt[3].classList.add("great")

            grades.push("Good work, and some room to improve. Your staff can be your greatest ambassadors to the rest of the world, and what they say may be trusted even more than official corporate content. ")
        } else if (total >= 24 && total <= 26) {
            tt[3].classList.add("mediocre")

            grades.push("Average performance on internal communications. This might mean you have some disgruntled staff who feel uninformed or misinformed. They will share their sentiments in their friend networks. ")
        } else if (total <= 23) {
            tt[3].classList.add("bad")

            grades.push("Plenty of room to improve your internal communication. Your staff can be a powerful force for good or bad! Have you activated a comprehensive internal communications plan?")
        }
        let grade4 = grades[0];
        this.setState({ grade4 })
    }
    submit5 = (event) => {
        event.preventDefault();
        let tt = document.querySelectorAll(".gradeSec")
        let { q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q37, q38, q39, q40, q41, q42, q43, q44, q45, q46, q47, q48, q49, q50, q51, q52, q53, q54, q55, q56, q57, q58, q59, q60, q61, employees } = this.state;
        let sec5 = [q39, q40, q41, q42, q43, q44, q45, q46, q47]
        let total = 0;
        let grades = [];
        sec5.map(e => {
            total += parseInt(e)
        })
        if (total >= 20) {
            tt[4].classList.add("great")

            grades.push("Very well done! Your organization has most or all things in place to build affinity and loyalty. You are showing the world that you are different from all the others, and best in class. And you are ready to spot any threats to your brand/reputation as soon as possible to keep damage to a minimum.")
        } else if (total >= 17 && total <= 19) {
            tt[4].classList.add("great")

            grades.push("Good, solid work. A few adjustments and you will be in the excellence zone.")
        } else if (total >= 15 && total <= 16) {
            tt[4].classList.add("mediocre")

            grades.push("Your brand and reputation work is average. Many companies have done a great job defining their brand, and maybe they have a fancy brand manual, but it is not always reflected in daily communication and products. Now, what good is that?")
        } else if (total <= 14) {
            tt[4].classList.add("bad")

            grades.push("It looks like you have room to improve how you define and communicate your brand, and how you make yourself ready to guard your reputation when a crisis emerges. It can take years to recover from an attack on your reputation, so it’s worth your time to be ready with proactive and reactive communication.")
        }
        let grade5 = grades[0];
        this.setState({ grade5 })
    }
    submit6 = (event) => {
        event.preventDefault();
        let tt = document.querySelectorAll(".gradeSec")
        let { q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q37, q38, q39, q40, q41, q42, q43, q44, q45, q46, q47, q48, q49, q50, q51, q52, q53, q54, q55, q56, q57, q58, q59, q60, q61, employees } = this.state;
        let sec6 = [q48, q49, q50, q51, q52]
        let total = 0;
        let grades = [];
        sec6.map(e => {
            total += parseInt(e)
        })
        if (total >= 16) {
            tt[5].classList.add("great")

            grades.push("You have well-developed and active social media channels that are properly managed. Excellent!")
        } else if (total >= 14 && total <= 15) {
            tt[5].classList.add("great")

            grades.push("Nice work! How might you go from good to great? Maybe do more experimenting and measuring the results?")
        } else if (total >= 12 && total <= 13) {
            tt[5].classList.add("mediocre")

            grades.push("Looks like your use of digital and social media is average, which can be a big yawn if your competition is nailing it.")
        } else if (total <= 11) {
            tt[5].classList.add("bad")

            grades.push(" It is likely that many more people would be interested in your product or service if you had a more engaging and strategic approach to digital/social communication.")
        }
        let grade6 = grades[0];
        this.setState({ grade6 })
    }
    submit7 = (event) => {
        event.preventDefault();
        let tt = document.querySelectorAll(".gradeSec")
        let { q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q37, q38, q39, q40, q41, q42, q43, q44, q45, q46, q47, q48, q49, q50, q51, q52, q53, q54, q55, q56, q57, q58, q59, q60, q61, employees } = this.state;
        let sec7 = [q53, q54, q55, q56]
        let total = 0;
        let grades = [];
        sec7.map(e => {
            total += parseInt(e)
        })
        if (total >= 25) {
            tt[6].classList.add("great")

            grades.push("Superb! Your communications team is equipped with state-of-the art tools.")
        } else if (total >= 22 && total <= 24) {
            tt[6].classList.add("great")

            grades.push("Pretty good job. The most important improvements to make first are to security and storage, and your IT team should be able to support you.")
        } else if (total >= 19 && total <= 21) {
            tt[6].classList.add("mediocre")

            grades.push("Communicators need the very best equipment and security to do their jobs. Don’t let this be your weakest link.")
        } else if (total <= 18) {
            tt[6].classList.add("bad")

            grades.push("How can communicators do quality work without the tools? Hopefully this isn’t a budget problem. Good equipment is not as expensive as it used to be; it is well worth the price.")
        }
        let grade7 = grades[0];
        this.setState({ grade7 })
    }
    submit8 = (event) => {
        event.preventDefault();
        let tt = document.querySelectorAll(".gradeSec")
        let { q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q37, q38, q39, q40, q41, q42, q43, q44, q45, q46, q47, q48, q49, q50, q51, q52, q53, q54, q55, q56, q57, q58, q59, q60, q61, employees } = this.state;
        let sec8 = [q57, q58, q59, q60, q61]
        let total = 0;
        let grades = [];
        sec8.map(e => {
            total += parseInt(e)
        })
        if (total >= 12) {
            tt[7].classList.add("great")

            grades.push("Wow, excellent job. Effective communication is evolving fast, and so is your organization.")
        } else if (total == 11) {
            tt[7].classList.add("great")

            grades.push("It seems that innovation is flowing but could be better.")
        } else if (total >= 9 && total <= 10) {
            tt[7].classList.add("mediocre")

            grades.push("Average. Does your team have enough time, resources and incentive to try new things? Do they know that failing forward is OK?")
        } else if (sec8 <= 8) {
            tt[7].classList.add("bad")

            grades.push("Looks like you can do more to stimulate innovation. What could be done first? Maybe a retreat for your communications team, with innovation being the single agenda item?")
        }
        let grade8 = grades[0];
        this.setState({ grade8 })
    }
    grade = (sec1, sec2, sec3, sec4, sec5, sec6, sec7, sec8, total) => {
        let grades = [];
        let tt = document.querySelectorAll(".gradeSec")
        console.log(tt)
        // section 1
        if (sec1 >= 34) {
            grades.push("Excellent. You have built a very solid foundation that will deliver business impact.")
            tt[0].classList.add("great")
        } else if (sec1 >= 30 && sec1 <= 34) {
            tt[0].classList.add("great")
            grades.push("Good work! Make sure you keep the strategy updated when unexpected risks and opportunities come along.")
        } else if (sec1 >= 26 && sec1 <= 29) {
            tt[0].classList.add("mediocre")
            grades.push("Just average. Fix the gaps and it will help you prove how much value is being delivered by the communications department.")
        } else if (sec1 <= 25) {
            tt[0].classList.add("bad")
            grades.push("You have opportunity to build a better strategy, measurement and reporting arrangement!")
        }
        // section 2
        if (sec2 >= 56) {
            grades.push("Congratulations! Your communications team is well positioned in your organization, and you are positioned to earn high impact.")
            tt[1].classList.add("great")
        } else if (sec2 >= 50 && sec2 <= 55) {
            tt[1].classList.add("great")
            grades.push("Overall, great work. It shouldn’t be too difficult to fix the gaps and set up your communications to work wonders for your business goals.")
        } else if (sec2 >= 43 && sec2 <= 54) {
            tt[1].classList.add("mediocre")
            grades.push("It appears that your communications function is average, but not best practice. Give your communications team the best possible chance to succeed. It begins with proper org structure and reporting relationships, expecting them to provide valuable intelligence about your stakeholders, and making sure they are participating in business deliberations.")
        } else if (sec2 <= 42) {
            tt[1].classList.add("bad")

            grades.push("It’s worth your time to adjust your communications scope, role and org structure. Research shows that a poor communication function can cost a business the equivalent of one day’s salary for every employee… every week!")
        }
        // section3
        if (sec3 >= 58) {
            tt[2].classList.add("great")

            grades.push("Fantastic work! It seems that you have a strong chief communicator with a team of skilled, motivated people who are focused on priorities and motivated to grow.")
        } else if (sec3 >= 51 && sec3 <= 57) {
            tt[2].classList.add("great")

            grades.push("Not bad. Share this assessment with your chief communicator and discuss the gaps. Soon you will be in the excellent zone.")
        } else if (sec3 >= 45 && sec3 <= 50) {
            tt[2].classList.add("mediocre")

            grades.push("Average is just not good enough. Make sure your chief communicator is getting good support to address the weaknesses.")
        } else if (sec3 <= 44) {
            tt[2].classList.add("bad")

            grades.push("It appears you have room to improve how you use your people to establish a communications department that will deliver maximum impact for the business. Usually this requires heavy involvement from the HR team.")
        }
        // section 4
        if (sec4 >= 31) {
            tt[3].classList.add("great")

            grades.push("Big congratulations! Excellence in communication begins internally, and it appears that you have made the right communication moves to keep your staff informed and motivated. ")
        } else if (sec4 >= 27 && sec4 <= 30) {
            tt[3].classList.add("great")

            grades.push("Good work, and some room to improve. Your staff can be your greatest ambassadors to the rest of the world, and what they say may be trusted even more than official corporate content. ")
        } else if (sec4 >= 24 && sec4 <= 26) {
            tt[3].classList.add("mediocre")

            grades.push("Average performance on internal communications. This might mean you have some disgruntled staff who feel uninformed or misinformed. They will share their sentiments in their friend networks.")
        } else if (sec4 <= 23) {
            tt[3].classList.add("bad")

            grades.push("Plenty of room to improve your internal communication. Your staff can be a powerful force for good or bad! Have you activated a comprehensive internal communications plan?")
        }
        // section 5
        if (sec5 >= 20) {
            tt[4].classList.add("great")

            grades.push("Very well done! Your organization has most or all things in place to build affinity and loyalty. You are showing the world that you are different from all the others, and best in class. And you are ready to spot any threats to your brand/reputation as soon as possible to keep damage to a minimum.")
        } else if (sec5 >= 17 && sec5 <= 19) {
            tt[4].classList.add("great")

            grades.push("Good, solid work. A few adjustments and you will be in the excellence zone.")
        } else if (sec5 >= 15 && sec5 <= 16) {
            tt[4].classList.add("mediocre")

            grades.push("Your brand and reputation work is average. Many companies have done a great job defining their brand, and maybe they have a fancy brand manual, but it is not always reflected in daily communication and products. Now, what good is that?")
        } else if (sec5 <= 14) {
            tt[4].classList.add("bad")

            grades.push("It looks like you have room to improve how you define and communicate your brand, and how you make yourself ready to guard your reputation when a crisis emerges. It can take years to recover from an attack on your reputation, so it’s worth your time to be ready with proactive and reactive communication.")
        }
        // section 6
        if (sec6 >= 16) {
            tt[5].classList.add("great")

            grades.push("You have well-developed and active social media channels that are properly managed. Excellent!")
        } else if (sec6 >= 14 && sec6 <= 15) {
            tt[5].classList.add("great")

            grades.push("Nice work! How might you go from good to great? Maybe do more experimenting and measuring the results?")
        } else if (sec6 >= 12 && sec6 <= 13) {
            tt[5].classList.add("mediocre")

            grades.push("Looks like your use of digital and social media is average, which can be a big yawn if your competition is nailing it.")
        } else if (sec6 <= 11) {
            tt[5].classList.add("bad")

            grades.push("It is likely that many more people would be interested in your product or service if you had a more engaging and strategic approach to digital/social communication.")
        }
        // section7
        if (sec7 >= 25) {
            tt[6].classList.add("great")

            grades.push("Superb! Your communications team is equipped with state-of-the art tools.")
        } else if (sec7 >= 22 && sec7 <= 24) {
            tt[6].classList.add("great")

            grades.push("Pretty good job. The most important improvements to make first are to security and storage, and your IT team should be able to support you.")
        } else if (sec7 >= 19 && sec7 <= 21) {
            tt[6].classList.add("mediocre")

            grades.push("Communicators need the very best equipment and security to do their jobs. Don’t let this be your weakest link.")
        } else if (sec7 <= 18) {
            tt[6].classList.add("bad")

            grades.push("How can communicators do quality work without the tools? Hopefully this isn’t a budget problem. Good equipment is not as expensive as it used to be; it is well worth the price.")
        }
        // section8`
        if (sec8 >= 12) {
            tt[7].classList.add("great")

            grades.push("Wow, excellent job. Effective communication is evolving fast, and so is your organization.")
        } else if (sec8 == 11) {
            tt[7].classList.add("great")

            grades.push("It seems that innovation is flowing but could be better.")
        } else if (sec8 >= 9 && sec8 <= 10) {
            tt[7].classList.add("mediocre")

            grades.push("Average. Does your team have enough time, resources and incentive to try new things? Do they know that failing forward is OK?")
        } else if (sec8 <= 8) {
            tt[7].classList.add("bad")

            grades.push("Looks like you can do more to stimulate innovation. What could be done first? Maybe a retreat for your communications team, with innovation being the single agenda item?")
        }

        emailjs.send("service_1c75aee", "template_vx0eg5p", {
            to_name: this.state.name,
            user_email: this.state.email,
            reply_to: "info@greaterlife.com",
            total_score: total + " out of a possible 282 points."
        });
        return grades;
    }
    handleChange = (event) => {

        // console.log('is this happening')
        event.preventDefault();
        // getting name and value from event.target
        const { name, value } = event.target;

        this.setState({ [name]: value }, () => {
            // console.log(this.state);
        });
        // let { company_name, company_address, company_duns, company_url, company_email, company_phone } = this.state
    }
    handleSubmit = (event) => {
        // form will clear inputs before capture without this line
        event.preventDefault();
        let modal = document.getElementById("myModal");

        modal.style.display = "block";
        let { q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q37, q38, q39, q40, q41, q42, q43, q44, q45, q46, q47, q48, q49, q50, q51, q52, q53, q54, q55, q56, q57, q58, q59, q60, q61, employees } = this.state;
        // organize by section
        let sec1 = [q1, q2, q3, q4, q5, q6]
        let sec2 = [q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20]
        let sec3 = [q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32]
        let sec4 = [q33, q34, q35, q36, q37, q38]
        let sec5 = [q39, q40, q41, q42, q43, q44, q45, q46, q47]
        let sec6 = [q48, q49, q50, q51, q52]
        let sec7 = [q53, q54, q55, q56]
        let sec8 = [q57, q58, q59, q60, q61]
        let total1 = 0;
        let total2 = 0;
        let total3 = 0;
        let total4 = 0;
        let total5 = 0;
        let total6 = 0;
        let total7 = 0;
        let total8 = 0;
        sec1.map(e => {
            total1 += parseInt(e)
        })
        sec8.map(e => {
            total8 += parseInt(e)
        })
        sec2.map(e => {
            total2 += parseInt(e)
        })
        sec3.map(e => {
            total3 += parseInt(e)
        })
        sec4.map(e => {
            total4 += parseInt(e)
        })
        sec5.map(e => {
            total5 += parseInt(e)
        })
        sec6.map(e => {
            total6 += parseInt(e)
        })
        sec7.map(e => {
            total7 += parseInt(e)
        })
        // name: '',
        //     title: '',
        //     company: '',
        //     email: '',
        //     phone: '',
        let total = total1 + total2 + total3 + total4 + total5 + total6 + total7 + total8
        this.setState({ total }, () => {
            console.log(total)

        })
        let grades = this.grade(total1, total2, total3, total4, total5, total6, total7, total8, total);
        // this.setState

        this.setState({ grades }, () => {
            // console.log(this.state);
        });
        console.log(grades)
        let { name, title, company, email, phone } = this.state;
        axios.post(
            'https://by3hy8ij17.execute-api.us-west-2.amazonaws.com/Dev',
            {
                test: "TESTING WOOOOO",
                name: name,
                title: title,
                company: company,
                email: email,
                phone: phone,
                total1: total1,
                total2: total2,
                total3: total3,
                total4: total4,
                total5: total5,
                total6: total6,
                total7: total7,
                total8: total8,
                q1: q1,
                q2: q2,
                q3: q3,
                q4: q4,
                q5: q5,
                q6: q6,
                q7: q7,
                q8: q8,
                q9: q9,
                q10: q10,
                q11: q11,
                q12: q12,
                q13: q13,
                q14: q14,
                q15: q15,
                q16: q16,
                q17: q17,
                q18: q18,
                q19: q19,
                q20: q20,
                q21: q21,
                q22: q22,
                q23: q23,
                q24: q24,
                q25: q25,
                q26: q26,
                q27: q27,
                q28: q28,
                q29: q29,
                q30: q30,
                q31: q31,
                q32: q32,
                q33: q33,
                q34: q34,
                q35: q35,
                q36: q36,
                q37: q37,
                q38: q38,
                q39: q39,
                q40: q40,
                q41: q41,
                q42: q42,
                q43: q43,
                q44: q44,
                q45: q45,
                q46: q46,
                q47: q47,
                q48: q48,
                q49: q49,
                q50: q50,
                q51: q51,
                q52: q52,
                q53: q53,
                q54: q54,
                q55: q55,
                q56: q56,
                q57: q57,
                q58: q58,
                q59: q59,
                q60: q60,
                q61: q61,
                employees: employees

            }
        );
        // console.log("TESTING TOTAL BELOW")
        // console.log(total1)

        // make api call here

        // post request here https://by3hy8ij17.execute-api.us-west-2.amazonaws.com/Dev
        this.setState({ submitted: true }, () => {
            console.log(this.state);
        });
    }
    btn = () => {
        let modal = document.getElementById("myModal");

        modal.style.display = "block";
    }
    span = () => {
        let modal = document.getElementById("myModal");
        modal.style.display = "none";
    }
    win = () => {

    }
    clickFunction = (event) => {
        event.preventDefault();
        event.preventDefault();
        let buttonID = event.target.id;
        let array = document.querySelectorAll('.q');
        console.log('below')
        console.log(array)
        let question = "";
        let response = "";
        // when adding weights to certain grades this will need to change
        // move each condional up one if adding point to id
        if (event.target.id.length == 3) {
            // these wont change
            question = event.target.id[2];

            response = event.target.id[0];

            question = 'q' + question;
        } else if (event.target.id.length == 4) {
            // these wont change

            question = event.target.id[2] + event.target.id[3];
            response = event.target.id[0];

            question = 'q' + question;
        }

        this.setState({ [question]: response }, () => {
            {
                // console.log(this.state)
            }
        })
        let targetID = event.target.id;
        console.log(event.target.id)
        let b1 = document.getElementById("0q1");
        let b2 = document.getElementById("4q1");
        let b3 = document.getElementById("8q1");
        let b4 = document.getElementById("0q2");
        let b5 = document.getElementById("3q2");
        let b6 = document.getElementById("6q2");
        let b7 = document.getElementById("0q3");
        let b8 = document.getElementById("3q3");
        let b9 = document.getElementById("6q3");
        let b10 = document.getElementById("0q4");
        let b11 = document.getElementById("3q4");
        let b12 = document.getElementById("6q4");
        let b13 = document.getElementById("0q5");
        let b14 = document.getElementById("3q5");
        let b15 = document.getElementById("6q5");

        let b16 = document.getElementById("0q6");
        let b17 = document.getElementById("3q6");
        let b18 = document.getElementById("6q6");
        let b19 = document.getElementById("0q7");
        let b20 = document.getElementById("3q7");
        let b21 = document.getElementById("6q7");

        let b22 = document.getElementById("0q8");
        let b23 = document.getElementById("3q8");
        let b24 = document.getElementById("6q8");
        let b25 = document.getElementById("0q9");
        let b26 = document.getElementById("1q9");
        let b27 = document.getElementById("2q9");

        let b28 = document.getElementById("0q10");
        let b29 = document.getElementById("1q10");
        let b30 = document.getElementById("2q10");
        let b31 = document.getElementById("0q11");
        let b32 = document.getElementById("1q11");
        let b33 = document.getElementById("2q11");

        let b34 = document.getElementById("0q12");
        let b35 = document.getElementById("1q12");
        let b36 = document.getElementById("2q12");
        let b37 = document.getElementById("0q13");
        let b38 = document.getElementById("1q13");
        let b39 = document.getElementById("2q13");

        let b40 = document.getElementById("0q14");
        let b41 = document.getElementById("3q14");
        let b42 = document.getElementById("6q14");
        let b43 = document.getElementById("0q15");
        let b44 = document.getElementById("3q15");
        let b45 = document.getElementById("6q15");

        let b46 = document.getElementById("0q16");
        let b47 = document.getElementById("3q16");
        let b48 = document.getElementById("6q16");
        let b49 = document.getElementById("0q17");
        let b50 = document.getElementById("3q17");
        let b51 = document.getElementById("6q17");

        let b52 = document.getElementById("0q18");
        let b53 = document.getElementById("3q18");
        let b54 = document.getElementById("6q18");
        let b55 = document.getElementById("0q19");
        let b56 = document.getElementById("3q19");
        let b57 = document.getElementById("6q19");

        let b58 = document.getElementById("0q20");
        let b59 = document.getElementById("3q20");
        let b60 = document.getElementById("6q20");
        let b61 = document.getElementById("0q21");
        let b62 = document.getElementById("4q21");
        let b63 = document.getElementById("8q21");

        let b64 = document.getElementById("0q22");
        let b65 = document.getElementById("4q22");
        let b66 = document.getElementById("8q22");
        let b67 = document.getElementById("0q23");
        let b68 = document.getElementById("3q23");
        let b69 = document.getElementById("6q23");

        let b70 = document.getElementById("0q24");
        let b71 = document.getElementById("1q24");
        let b72 = document.getElementById("2q24");
        let b73 = document.getElementById("0q25");
        let b74 = document.getElementById("1q25");
        let b75 = document.getElementById("2q25");


        let b76 = document.getElementById("0q26");
        let b77 = document.getElementById("1q26");
        let b78 = document.getElementById("2q26");
        let b79 = document.getElementById("0q27");
        let b80 = document.getElementById("3q27");
        let b81 = document.getElementById("6q27");

        let b82 = document.getElementById("0q28");
        let b83 = document.getElementById("3q28");
        let b84 = document.getElementById("6q28");
        let b85 = document.getElementById("0q29");
        let b86 = document.getElementById("3q29");
        let b87 = document.getElementById("6q29");

        let b88 = document.getElementById("0q30");
        let b89 = document.getElementById("1q30");
        let b90 = document.getElementById("2q30");
        let b91 = document.getElementById("0q31");
        let b92 = document.getElementById("4q31");
        let b93 = document.getElementById("8q31");

        let b94 = document.getElementById("0q32");
        let b95 = document.getElementById("4q32");
        let b96 = document.getElementById("8q32");
        let b97 = document.getElementById("0q33");
        let b98 = document.getElementById("3q33");
        let b99 = document.getElementById("6q33");

        let b100 = document.getElementById("0q34");
        let b101 = document.getElementById("3q34");
        let b102 = document.getElementById("6q34");
        let b103 = document.getElementById("0q35");
        let b104 = document.getElementById("3q35");
        let b105 = document.getElementById("6q35");

        let b106 = document.getElementById("0q36");
        let b107 = document.getElementById("1q36");
        let b108 = document.getElementById("2q36");
        let b109 = document.getElementById("0q37");
        let b110 = document.getElementById("3q37");
        let b111 = document.getElementById("6q37");

        let b112 = document.getElementById("0q38");
        let b113 = document.getElementById("4q38");
        let b114 = document.getElementById("8q38");
        let b115 = document.getElementById("0q39");
        let b116 = document.getElementById("3q39");
        let b117 = document.getElementById("6q39");

        let b118 = document.getElementById("0q40");
        let b119 = document.getElementById("1q40");
        let b120 = document.getElementById("2q40");
        let b121 = document.getElementById("0q41");
        let b122 = document.getElementById("1q41");
        let b123 = document.getElementById("2q41");


        let b124 = document.getElementById("0q42");
        let b125 = document.getElementById("1q42");
        let b126 = document.getElementById("2q42");
        let b127 = document.getElementById("0q43");
        let b128 = document.getElementById("1q43");
        let b129 = document.getElementById("2q43");

        let b130 = document.getElementById("0q44");
        let b131 = document.getElementById("1q44");
        let b132 = document.getElementById("2q44");
        let b133 = document.getElementById("0q45");
        let b134 = document.getElementById("1q45");
        let b135 = document.getElementById("2q45");

        let b136 = document.getElementById("0q46");
        let b137 = document.getElementById("1q46");
        let b138 = document.getElementById("2q46");
        let b139 = document.getElementById("0q47");
        let b140 = document.getElementById("1q47");
        let b141 = document.getElementById("2q47");

        let b142 = document.getElementById("0q48");
        let b143 = document.getElementById("3q48");
        let b144 = document.getElementById("6q48");
        let b145 = document.getElementById("0q49");
        let b146 = document.getElementById("3q49");
        let b147 = document.getElementById("6q49");

        let b148 = document.getElementById("0q50");
        let b149 = document.getElementById("1q50");
        let b150 = document.getElementById("2q50");
        let b151 = document.getElementById("0q51");
        let b152 = document.getElementById("1q51");
        let b153 = document.getElementById("2q51");

        let b154 = document.getElementById("0q52");
        let b155 = document.getElementById("1q52");
        let b156 = document.getElementById("2q52");
        let b157 = document.getElementById("0q53");
        let b158 = document.getElementById("4q53");
        let b159 = document.getElementById("8q53");

        let b160 = document.getElementById("0q54");
        let b161 = document.getElementById("4q54");
        let b162 = document.getElementById("8q54");
        let b163 = document.getElementById("0q55");
        let b164 = document.getElementById("3q55");
        let b165 = document.getElementById("6q55");

        let b166 = document.getElementById("0q56");
        let b167 = document.getElementById("3q56");
        let b168 = document.getElementById("6q56");
        let b169 = document.getElementById("0q57");
        let b170 = document.getElementById("3q57");
        let b171 = document.getElementById("6q57");

        let b172 = document.getElementById("0q58");
        let b173 = document.getElementById("1q58");
        let b174 = document.getElementById("2q58");
        let b175 = document.getElementById("0q59");
        let b176 = document.getElementById("1q59");
        let b177 = document.getElementById("2q59");

        let b178 = document.getElementById("0q60");
        let b179 = document.getElementById("1q60");
        let b180 = document.getElementById("2q60");
        let b181 = document.getElementById("0q61");
        let b182 = document.getElementById("1q61");
        let b183 = document.getElementById("2q61");

        if (targetID == b1.id) {
            b2.classList.remove("clicked")
            b3.classList.remove("clicked")
            b1.classList.add("clicked")
        } else if (targetID == b2.id) {
            b1.classList.remove('clicked')
            b3.classList.remove('clicked')
            b2.classList.add('clicked')
        } else if (targetID == b3.id) {
            b1.classList.remove('clicked')
            b2.classList.remove('clicked')
            b3.classList.add('clicked')
        } else if (targetID == b4.id) {
            b6.classList.remove('clicked')
            b5.classList.remove('clicked')
            b4.classList.add('clicked')
        }
        else if (targetID == b5.id) {
            b6.classList.remove('clicked')
            b4.classList.remove('clicked')
            b5.classList.add('clicked')
        } else if (targetID == b6.id) {
            b4.classList.remove('clicked')
            b5.classList.remove('clicked')
            b6.classList.add('clicked')
        } else if (targetID == b7.id) {
            b9.classList.remove('clicked')
            b8.classList.remove('clicked')
            b7.classList.add('clicked')
        } else if (targetID == b8.id) {
            b9.classList.remove('clicked')
            b7.classList.remove('clicked')
            b8.classList.add('clicked')
        } else if (targetID == b9.id) {
            b7.classList.remove('clicked')
            b8.classList.remove('clicked')
            b9.classList.add('clicked')
        } else if (targetID == b10.id) {
            b12.classList.remove('clicked')
            b11.classList.remove('clicked')
            b10.classList.add('clicked')
        } else if (targetID == b11.id) {
            b12.classList.remove('clicked')
            b10.classList.remove('clicked')
            b11.classList.add('clicked')
        } else if (targetID == b12.id) {
            b11.classList.remove('clicked')
            b10.classList.remove('clicked')
            b12.classList.add('clicked')
        } else if (targetID == b13.id) {
            b15.classList.remove('clicked')
            b14.classList.remove('clicked')
            b13.classList.add('clicked')
        } else if (targetID == b14.id) {
            b15.classList.remove('clicked')
            b13.classList.remove('clicked')
            b14.classList.add('clicked')
        } else if (targetID == b15.id) {
            b13.classList.remove('clicked')
            b14.classList.remove('clicked')
            b15.classList.add('clicked')
        } else if (targetID == b16.id) {
            b18.classList.remove('clicked')
            b17.classList.remove('clicked')
            b16.classList.add('clicked')
        } else if (targetID == b17.id) {
            b18.classList.remove('clicked')
            b16.classList.remove('clicked')
            b17.classList.add('clicked')
        } else if (targetID == b18.id) {
            b16.classList.remove('clicked')
            b17.classList.remove('clicked')
            b18.classList.add('clicked')
        } else if (targetID == b19.id) {
            b21.classList.remove('clicked')
            b20.classList.remove('clicked')
            b19.classList.add('clicked')
        } else if (targetID == b20.id) {
            b21.classList.remove('clicked')
            b19.classList.remove('clicked')
            b20.classList.add('clicked')
        } else if (targetID == b21.id) {
            b20.classList.remove('clicked')
            b19.classList.remove('clicked')
            b21.classList.add('clicked')
        } else if (targetID == b22.id) {
            b24.classList.remove('clicked')
            b23.classList.remove('clicked')
            b22.classList.add('clicked')
        } else if (targetID == b23.id) {
            b24.classList.remove('clicked')
            b22.classList.remove('clicked')
            b23.classList.add('clicked')
        } else if (targetID == b24.id) {
            b22.classList.remove('clicked')
            b23.classList.remove('clicked')
            b24.classList.add('clicked')
        } else if (targetID == b25.id) {
            b27.classList.remove('clicked')
            b26.classList.remove('clicked')
            b25.classList.add('clicked')
        } else if (targetID == b26.id) {
            b27.classList.remove('clicked')
            b25.classList.remove('clicked')
            b26.classList.add('clicked')
        } else if (targetID == b27.id) {
            b26.classList.remove('clicked')
            b25.classList.remove('clicked')
            b27.classList.add('clicked')
        } else if (targetID == b28.id) {
            b30.classList.remove('clicked')
            b29.classList.remove('clicked')
            b28.classList.add('clicked')
        } else if (targetID == b29.id) {
            // console.log("AYO")
            b30.classList.remove('clicked')
            b28.classList.remove('clicked')
            b29.classList.add('clicked')
        } else if (targetID == b30.id) {
            b28.classList.remove('clicked')
            b29.classList.remove('clicked')
            b30.classList.add('clicked')
        } else if (targetID == b31.id) {
            b33.classList.remove('clicked')
            b32.classList.remove('clicked')
            b31.classList.add('clicked')
        } else if (targetID == b32.id) {
            b33.classList.remove('clicked')
            b31.classList.remove('clicked')
            b32.classList.add('clicked')
        } else if (targetID == b33.id) {
            b31.classList.remove('clicked')
            b32.classList.remove('clicked')
            b33.classList.add('clicked')
        } else if (targetID == b34.id) {
            b36.classList.remove('clicked')
            b35.classList.remove('clicked')
            b34.classList.add('clicked')
        } else if (targetID == b35.id) {
            b36.classList.remove('clicked')
            b34.classList.remove('clicked')
            b35.classList.add('clicked')
        } else if (targetID == b36.id) {
            b34.classList.remove('clicked')
            b35.classList.remove('clicked')
            b36.classList.add('clicked')
        } else if (targetID == b37.id) {
            b39.classList.remove('clicked')
            b38.classList.remove('clicked')
            b37.classList.add('clicked')
        } else if (targetID == b38.id) {
            b39.classList.remove('clicked')
            b37.classList.remove('clicked')
            b38.classList.add('clicked')
        } else if (targetID == b39.id) {
            b37.classList.remove('clicked')
            b38.classList.remove('clicked')
            b39.classList.add('clicked')
        } else if (targetID == b40.id) {
            b42.classList.remove('clicked')
            b41.classList.remove('clicked')
            b40.classList.add('clicked')
        } else if (targetID == b41.id) {
            b42.classList.remove('clicked')
            b40.classList.remove('clicked')
            b41.classList.add('clicked')
        } else if (targetID == b42.id) {
            b40.classList.remove('clicked')
            b41.classList.remove('clicked')
            b42.classList.add('clicked')
        } else if (targetID == b43.id) {
            b45.classList.remove('clicked')
            b44.classList.remove('clicked')
            b43.classList.add('clicked')
        } else if (targetID == b44.id) {
            b45.classList.remove('clicked')
            b43.classList.remove('clicked')
            b44.classList.add('clicked')
        } else if (targetID == b45.id) {
            b43.classList.remove('clicked')
            b44.classList.remove('clicked')
            b45.classList.add('clicked')
        } else if (targetID == b46.id) {
            b48.classList.remove('clicked')
            b47.classList.remove('clicked')
            b46.classList.add('clicked')
        } else if (targetID == b47.id) {
            b48.classList.remove('clicked')
            b46.classList.remove('clicked')
            b47.classList.add('clicked')
        } else if (targetID == b48.id) {
            b47.classList.remove('clicked')
            b46.classList.remove('clicked')
            b48.classList.add('clicked')
        } else if (targetID == b49.id) {
            b51.classList.remove('clicked')
            b50.classList.remove('clicked')
            b49.classList.add('clicked')
        } else if (targetID == b50.id) {
            b51.classList.remove('clicked')
            b49.classList.remove('clicked')
            b50.classList.add('clicked')
        } else if (targetID == b51.id) {
            b49.classList.remove('clicked')
            b50.classList.remove('clicked')
            b51.classList.add('clicked')
        } else if (targetID == b52.id) {
            b54.classList.remove('clicked')
            b53.classList.remove('clicked')
            b52.classList.add('clicked')
        } else if (targetID == b53.id) {
            b54.classList.remove('clicked')
            b52.classList.remove('clicked')
            b53.classList.add('clicked')
        } else if (targetID == b54.id) {
            b52.classList.remove('clicked')
            b53.classList.remove('clicked')
            b54.classList.add('clicked')
        } else if (targetID == b55.id) {
            b57.classList.remove('clicked')
            b56.classList.remove('clicked')
            b55.classList.add('clicked')
        } else if (targetID == b56.id) {
            b57.classList.remove('clicked')
            b55.classList.remove('clicked')
            b56.classList.add('clicked')
        } else if (targetID == b57.id) {
            b55.classList.remove('clicked')
            b56.classList.remove('clicked')
            b57.classList.add('clicked')
        } else if (targetID == b58.id) {
            b60.classList.remove('clicked')
            b59.classList.remove('clicked')
            b58.classList.add('clicked')
        } else if (targetID == b59.id) {
            b60.classList.remove('clicked')
            b58.classList.remove('clicked')
            b59.classList.add('clicked')
        } else if (targetID == b60.id) {
            b58.classList.remove('clicked')
            b59.classList.remove('clicked')
            b60.classList.add('clicked')
        } else if (targetID == b61.id) {
            b63.classList.remove('clicked')
            b62.classList.remove('clicked')
            b61.classList.add('clicked')
        } else if (targetID == b62.id) {
            b63.classList.remove('clicked')
            b61.classList.remove('clicked')
            b62.classList.add('clicked')
        } else if (targetID == b63.id) {
            b61.classList.remove('clicked')
            b62.classList.remove('clicked')
            b63.classList.add('clicked')
        } else if (targetID == b64.id) {
            b66.classList.remove('clicked')
            b65.classList.remove('clicked')
            b64.classList.add('clicked')
        } else if (targetID == b65.id) {
            b66.classList.remove('clicked')
            b64.classList.remove('clicked')
            b65.classList.add('clicked')
        } else if (targetID == b66.id) {
            b65.classList.remove('clicked')
            b64.classList.remove('clicked')
            b66.classList.add('clicked')
        } else if (targetID == b67.id) {
            b69.classList.remove('clicked')
            b68.classList.remove('clicked')
            b67.classList.add('clicked')
        } else if (targetID == b68.id) {
            b69.classList.remove('clicked')
            b67.classList.remove('clicked')
            b68.classList.add('clicked')
        } else if (targetID == b69.id) {
            b67.classList.remove('clicked')
            b68.classList.remove('clicked')
            b69.classList.add('clicked')
        } else if (targetID == b70.id) {
            b72.classList.remove('clicked')
            b71.classList.remove('clicked')
            b70.classList.add('clicked')
        } else if (targetID == b71.id) {
            b72.classList.remove('clicked')
            b70.classList.remove('clicked')
            b71.classList.add('clicked')
        } else if (targetID == b72.id) {
            b70.classList.remove('clicked')
            b71.classList.remove('clicked')
            b72.classList.add('clicked')
        } else if (targetID == b73.id) {
            b75.classList.remove('clicked')
            b74.classList.remove('clicked')
            b73.classList.add('clicked')
        } else if (targetID == b74.id) {
            b75.classList.remove('clicked')
            b73.classList.remove('clicked')
            b74.classList.add('clicked')
        } else if (targetID == b75.id) {
            b73.classList.remove('clicked')
            b74.classList.remove('clicked')
            b75.classList.add('clicked')
        } else if (targetID == b76.id) {
            b78.classList.remove('clicked')
            b77.classList.remove('clicked')
            b76.classList.add('clicked')
        } else if (targetID == b77.id) {
            b78.classList.remove('clicked')
            b76.classList.remove('clicked')
            b77.classList.add('clicked')
        } else if (targetID == b78.id) {
            b76.classList.remove('clicked')
            b77.classList.remove('clicked')
            b78.classList.add('clicked')
        } else if (targetID == b79.id) {
            b81.classList.remove('clicked')
            b80.classList.remove('clicked')
            b79.classList.add('clicked')
        } else if (targetID == b80.id) {
            b81.classList.remove('clicked')
            b79.classList.remove('clicked')
            b80.classList.add('clicked')
        } else if (targetID == b81.id) {
            b79.classList.remove('clicked')
            b80.classList.remove('clicked')
            b81.classList.add('clicked')
        } else if (targetID == b82.id) {
            b84.classList.remove('clicked')
            b83.classList.remove('clicked')
            b82.classList.add('clicked')
        } else if (targetID == b83.id) {
            b84.classList.remove('clicked')
            b82.classList.remove('clicked')
            b83.classList.add('clicked')
        } else if (targetID == b84.id) {
            b82.classList.remove('clicked')
            b83.classList.remove('clicked')
            b84.classList.add('clicked')
        } else if (targetID == b85.id) {
            b87.classList.remove('clicked')
            b86.classList.remove('clicked')
            b85.classList.add('clicked')
        } else if (targetID == b86.id) {
            b87.classList.remove('clicked')
            b85.classList.remove('clicked')
            b86.classList.add('clicked')
        } else if (targetID == b87.id) {
            b85.classList.remove('clicked')
            b86.classList.remove('clicked')
            b87.classList.add('clicked')
        } else if (targetID == b88.id) {
            b90.classList.remove('clicked')
            b89.classList.remove('clicked')
            b88.classList.add('clicked')
        } else if (targetID == b89.id) {
            b90.classList.remove('clicked')
            b88.classList.remove('clicked')
            b89.classList.add('clicked')
        } else if (targetID == b90.id) {
            b88.classList.remove('clicked')
            b89.classList.remove('clicked')
            b90.classList.add('clicked')
        } else if (targetID == b91.id) {
            b93.classList.remove('clicked')
            b92.classList.remove('clicked')
            b91.classList.add('clicked')
        } else if (targetID == b92.id) {
            b93.classList.remove('clicked')
            b91.classList.remove('clicked')
            b92.classList.add('clicked')
        } else if (targetID == b91.id) {
            b93.classList.remove('clicked')
            b92.classList.remove('clicked')
            b91.classList.add('clicked')
        } else if (targetID == b92.id) {
            b93.classList.remove('clicked')
            b91.classList.remove('clicked')
            b92.classList.add('clicked')
        } else if (targetID == b93.id) {
            b91.classList.remove('clicked')
            b92.classList.remove('clicked')
            b93.classList.add('clicked')
        } else if (targetID == b94.id) {
            b96.classList.remove('clicked')
            b95.classList.remove('clicked')
            b94.classList.add('clicked')
        } else if (targetID == b95.id) {
            b96.classList.remove('clicked')
            b94.classList.remove('clicked')
            b95.classList.add('clicked')
        } else if (targetID == b96.id) {
            b95.classList.remove('clicked')
            b94.classList.remove('clicked')
            b96.classList.add('clicked')
        } else if (targetID == b97.id) {
            b99.classList.remove('clicked')
            b98.classList.remove('clicked')
            b97.classList.add('clicked')
        } else if (targetID == b98.id) {
            b99.classList.remove('clicked')
            b97.classList.remove('clicked')
            b98.classList.add('clicked')
        } else if (targetID == b99.id) {
            b97.classList.remove('clicked')
            b98.classList.remove('clicked')
            b99.classList.add('clicked')
        } else if (targetID == b100.id) {
            b102.classList.remove('clicked')
            b101.classList.remove('clicked')
            b100.classList.add('clicked')
        } else if (targetID == b101.id) {
            b102.classList.remove('clicked')
            b100.classList.remove('clicked')
            b101.classList.add('clicked')
        } else if (targetID == b102.id) {
            b100.classList.remove('clicked')
            b101.classList.remove('clicked')
            b102.classList.add('clicked')
        } else if (targetID == b103.id) {
            b105.classList.remove('clicked')
            b104.classList.remove('clicked')
            b103.classList.add('clicked')
        } else if (targetID == b104.id) {
            b105.classList.remove('clicked')
            b103.classList.remove('clicked')
            b104.classList.add('clicked')
        } else if (targetID == b105.id) {
            b103.classList.remove('clicked')
            b104.classList.remove('clicked')
            b105.classList.add('clicked')
        } else if (targetID == b106.id) {
            b108.classList.remove('clicked')
            b107.classList.remove('clicked')
            b106.classList.add('clicked')
        } else if (targetID == b107.id) {
            b108.classList.remove('clicked')
            b106.classList.remove('clicked')
            b107.classList.add('clicked')
        } else if (targetID == b108.id) {
            b106.classList.remove('clicked')
            b107.classList.remove('clicked')
            b108.classList.add('clicked')
        } else if (targetID == b109.id) {
            b111.classList.remove('clicked')
            b110.classList.remove('clicked')
            b109.classList.add('clicked')
        } else if (targetID == b110.id) {
            b111.classList.remove('clicked')
            b109.classList.remove('clicked')
            b110.classList.add('clicked')
        } else if (targetID == b111.id) {
            b109.classList.remove('clicked')
            b110.classList.remove('clicked')
            b111.classList.add('clicked')
        } else if (targetID == b112.id) {
            b114.classList.remove('clicked')
            b113.classList.remove('clicked')
            b112.classList.add('clicked')
        } else if (targetID == b113.id) {
            b114.classList.remove('clicked')
            b112.classList.remove('clicked')
            b113.classList.add('clicked')
        } else if (targetID == b114.id) {
            b113.classList.remove('clicked')
            b112.classList.remove('clicked')
            b114.classList.add('clicked')
        } else if (targetID == b115.id) {
            b117.classList.remove('clicked')
            b116.classList.remove('clicked')
            b115.classList.add('clicked')
        } else if (targetID == b116.id) {
            b117.classList.remove('clicked')
            b115.classList.remove('clicked')
            b116.classList.add('clicked')
        } else if (targetID == b117.id) {
            b115.classList.remove('clicked')
            b116.classList.remove('clicked')
            b117.classList.add('clicked')
        } else if (targetID == b118.id) {
            b120.classList.remove('clicked')
            b119.classList.remove('clicked')
            b118.classList.add('clicked')
        } else if (targetID == b119.id) {
            b120.classList.remove('clicked')
            b118.classList.remove('clicked')
            b119.classList.add('clicked')
        } else if (targetID == b120.id) {
            b118.classList.remove('clicked')
            b119.classList.remove('clicked')
            b120.classList.add('clicked')
        } else if (targetID == b121.id) {
            b123.classList.remove('clicked')
            b122.classList.remove('clicked')
            b121.classList.add('clicked')
        } else if (targetID == b122.id) {
            b123.classList.remove('clicked')
            b121.classList.remove('clicked')
            b122.classList.add('clicked')
        } else if (targetID == b123.id) {
            b121.classList.remove('clicked')
            b122.classList.remove('clicked')
            b123.classList.add('clicked')
        } else if (targetID == b124.id) {
            b126.classList.remove('clicked')
            b125.classList.remove('clicked')
            b124.classList.add('clicked')
        } else if (targetID == b125.id) {
            b126.classList.remove('clicked')
            b124.classList.remove('clicked')
            b125.classList.add('clicked')
        } else if (targetID == b126.id) {
            b124.classList.remove('clicked')
            b125.classList.remove('clicked')
            b126.classList.add('clicked')
        } else if (targetID == b127.id) {
            b129.classList.remove('clicked')
            b128.classList.remove('clicked')
            b127.classList.add('clicked')
        } else if (targetID == b128.id) {
            b129.classList.remove('clicked')
            b127.classList.remove('clicked')
            b128.classList.add('clicked')
        } else if (targetID == b129.id) {
            b127.classList.remove('clicked')
            b128.classList.remove('clicked')
            b129.classList.add('clicked')
        } else if (targetID == b130.id) {
            b132.classList.remove('clicked')
            b131.classList.remove('clicked')
            b130.classList.add('clicked')
        } else if (targetID == b131.id) {
            b132.classList.remove('clicked')
            b130.classList.remove('clicked')
            b131.classList.add('clicked')
        } else if (targetID == b132.id) {
            b131.classList.remove('clicked')
            b130.classList.remove('clicked')
            b132.classList.add('clicked')
        } else if (targetID == b133.id) {
            b135.classList.remove('clicked')
            b134.classList.remove('clicked')
            b133.classList.add('clicked')
        } else if (targetID == b134.id) {
            b135.classList.remove('clicked')
            b133.classList.remove('clicked')
            b134.classList.add('clicked')
        } else if (targetID == b135.id) {
            b133.classList.remove('clicked')
            b134.classList.remove('clicked')
            b135.classList.add('clicked')
        } else if (targetID == b136.id) {
            b138.classList.remove('clicked')
            b137.classList.remove('clicked')
            b136.classList.add('clicked')
        } else if (targetID == b137.id) {
            b138.classList.remove('clicked')
            b136.classList.remove('clicked')
            b137.classList.add('clicked')
        } else if (targetID == b138.id) {
            b137.classList.remove('clicked')
            b136.classList.remove('clicked')
            b138.classList.add('clicked')
        } else if (targetID == b139.id) {
            b141.classList.remove('clicked')
            b140.classList.remove('clicked')
            b139.classList.add('clicked')
        } else if (targetID == b140.id) {
            b141.classList.remove('clicked')
            b139.classList.remove('clicked')
            b140.classList.add('clicked')
        } else if (targetID == b141.id) {
            b139.classList.remove('clicked')
            b140.classList.remove('clicked')
            b141.classList.add('clicked')
        }
        else if (targetID == b142.id) {
            b144.classList.remove('clicked')
            b143.classList.remove('clicked')
            b142.classList.add('clicked')
        } else if (targetID == b143.id) {
            b144.classList.remove('clicked')
            b142.classList.remove('clicked')
            b143.classList.add('clicked')
        } else if (targetID == b144.id) {
            b142.classList.remove('clicked')
            b143.classList.remove('clicked')
            b144.classList.add('clicked')
        } else if (targetID == b145.id) {
            b147.classList.remove('clicked')
            b146.classList.remove('clicked')
            b145.classList.add('clicked')
        } else if (targetID == b146.id) {
            b147.classList.remove('clicked')
            b145.classList.remove('clicked')
            b146.classList.add('clicked')
        } else if (targetID == b147.id) {
            b145.classList.remove('clicked')
            b146.classList.remove('clicked')
            b147.classList.add('clicked')
        } else if (targetID == b148.id) {
            b150.classList.remove('clicked')
            b149.classList.remove('clicked')
            b148.classList.add('clicked')
        } else if (targetID == b149.id) {
            b150.classList.remove('clicked')
            b148.classList.remove('clicked')
            b149.classList.add('clicked')
        } else if (targetID == b150.id) {
            b148.classList.remove('clicked')
            b149.classList.remove('clicked')
            b150.classList.add('clicked')
        } else if (targetID == b151.id) {
            b153.classList.remove('clicked')
            b152.classList.remove('clicked')
            b151.classList.add('clicked')
        } else if (targetID == b152.id) {
            b153.classList.remove('clicked')
            b151.classList.remove('clicked')
            b152.classList.add('clicked')
        } else if (targetID == b153.id) {
            b151.classList.remove('clicked')
            b152.classList.remove('clicked')
            b153.classList.add('clicked')
        } else if (targetID == b154.id) {
            b156.classList.remove('clicked')
            b155.classList.remove('clicked')
            b154.classList.add('clicked')
        } else if (targetID == b155.id) {
            b156.classList.remove('clicked')
            b154.classList.remove('clicked')
            b155.classList.add('clicked')
        } else if (targetID == b156.id) {
            b154.classList.remove('clicked')
            b155.classList.remove('clicked')
            b156.classList.add('clicked')
        } else if (targetID == b157.id) {
            b159.classList.remove('clicked')
            b158.classList.remove('clicked')
            b157.classList.add('clicked')
        } else if (targetID == b158.id) {
            b159.classList.remove('clicked')
            b157.classList.remove('clicked')
            b158.classList.add('clicked')
        } else if (targetID == b159.id) {
            b157.classList.remove('clicked')
            b158.classList.remove('clicked')
            b159.classList.add('clicked')
        } else if (targetID == b160.id) {
            b162.classList.remove('clicked')
            b161.classList.remove('clicked')
            b160.classList.add('clicked')
        } else if (targetID == b161.id) {
            b162.classList.remove('clicked')
            b160.classList.remove('clicked')
            b161.classList.add('clicked')
        } else if (targetID == b162.id) {
            b160.classList.remove('clicked')
            b161.classList.remove('clicked')
            b162.classList.add('clicked')
        } else if (targetID == b163.id) {
            b165.classList.remove('clicked')
            b164.classList.remove('clicked')
            b163.classList.add('clicked')
        } else if (targetID == b164.id) {
            b165.classList.remove('clicked')
            b163.classList.remove('clicked')
            b164.classList.add('clicked')
        } else if (targetID == b165.id) {
            b163.classList.remove('clicked')
            b164.classList.remove('clicked')
            b165.classList.add('clicked')
        } else if (targetID == b166.id) {
            b168.classList.remove('clicked')
            b167.classList.remove('clicked')
            b166.classList.add('clicked')
        } else if (targetID == b167.id) {
            b168.classList.remove('clicked')
            b166.classList.remove('clicked')
            b167.classList.add('clicked')
        } else if (targetID == b168.id) {
            b166.classList.remove('clicked')
            b167.classList.remove('clicked')
            b168.classList.add('clicked')
        } else if (targetID == b169.id) {
            b171.classList.remove('clicked')
            b170.classList.remove('clicked')
            b169.classList.add('clicked')
        } else if (targetID == b170.id) {
            b171.classList.remove('clicked')
            b169.classList.remove('clicked')
            b170.classList.add('clicked')
        } else if (targetID == b171.id) {
            b169.classList.remove('clicked')
            b170.classList.remove('clicked')
            b171.classList.add('clicked')
        } else if (targetID == b172.id) {
            b174.classList.remove('clicked')
            b173.classList.remove('clicked')
            b172.classList.add('clicked')
        } else if (targetID == b173.id) {
            b174.classList.remove('clicked')
            b172.classList.remove('clicked')
            b173.classList.add('clicked')
        } else if (targetID == b174.id) {
            b172.classList.remove('clicked')
            b173.classList.remove('clicked')
            b174.classList.add('clicked')
        } else if (targetID == b175.id) {
            b177.classList.remove('clicked')
            b176.classList.remove('clicked')
            b175.classList.add('clicked')
        } else if (targetID == b176.id) {
            b177.classList.remove('clicked')
            b175.classList.remove('clicked')
            b176.classList.add('clicked')
        } else if (targetID == b177.id) {
            b175.classList.remove('clicked')
            b176.classList.remove('clicked')
            b177.classList.add('clicked')
        } else if (targetID == b178.id) {
            b180.classList.remove('clicked')
            b179.classList.remove('clicked')
            b178.classList.add('clicked')
        } else if (targetID == b179.id) {
            b180.classList.remove('clicked')
            b178.classList.remove('clicked')
            b179.classList.add('clicked')
        } else if (targetID == b180.id) {
            b178.classList.remove('clicked')
            b179.classList.remove('clicked')
            b180.classList.add('clicked')
        } else if (targetID == b181.id) {
            b183.classList.remove('clicked')
            b182.classList.remove('clicked')
            b181.classList.add('clicked')
        } else if (targetID == b182.id) {
            b183.classList.remove('clicked')
            b181.classList.remove('clicked')
            b182.classList.add('clicked')
        } else if (targetID == b183.id) {
            b181.classList.remove('clicked')
            b182.classList.remove('clicked')
            b183.classList.add('clicked')
        }
    }
    render() {
        // const { valid, phoneError, emailError, success, submitted } = this.state;
        let { q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20, q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32, q33, q34, q35, q36, q37, q38, q39, q40, q41, q42, q43, q44, q45, q46, q47, q48, q49, q50, q51, q52, q53, q54, q55, q56, q57, q58, q59, q60, q61 } = this.state;
        // organize by section
        let sec1 = [q1, q2, q3, q4, q5, q6]
        let sec2 = [q7, q8, q9, q10, q11, q12, q13, q14, q15, q16, q17, q18, q19, q20]
        let sec3 = [q21, q22, q23, q24, q25, q26, q27, q28, q29, q30, q31, q32]
        let sec4 = [q33, q34, q35, q36, q37, q38]
        let sec5 = [q39, q40, q41, q42, q43, q44, q45, q46, q47]
        let sec6 = [q48, q49, q50, q51, q52]
        let sec7 = [q53, q54, q55, q56]
        let sec8 = [q57, q58, q59, q60, q61]
        let total1 = 0;
        let total2 = 0;
        let total3 = 0;
        let total4 = 0;
        let total5 = 0;
        let total6 = 0;
        let total7 = 0;
        let total8 = 0;
        sec1.map(e => {
            total1 += parseInt(e)
        })
        sec8.map(e => {
            total8 += parseInt(e)
        })
        sec2.map(e => {
            total2 += parseInt(e)
        })
        sec3.map(e => {
            total3 += parseInt(e)
        })
        sec4.map(e => {
            total4 += parseInt(e)
        })
        sec5.map(e => {
            total5 += parseInt(e)
        })
        sec6.map(e => {
            total6 += parseInt(e)
        })
        sec7.map(e => {
            total7 += parseInt(e)
        })
        let { grades, grade1, grade2, grade3, grade4, grade5, grade6, grade7, grade8 } = this.state
        // Get the modal
        let modal = document.getElementById("myModal");
        // console.log(grade2)
        let grade11 = ''
        let grade22 = ''
        let grade33 = ''
        let grade44 = ''
        let grade55 = ''
        let grade66 = ''
        let grade77 = ''
        let grade88 = ''
        if (grade1.length > 3) {
            grade11 = grade1 + total1 + ' points out of 38'
        } if (grade2.length > 3) {
            grade22 = grade2 + total2 + ' points out of 64';
        } if (grade3.length > 3) {
            grade33 = grade3 + total3 + ' points out of 64';
        } if (grade4.length > 3) {
            grade44 = grade4 + total4 + ' points out of 34';
        } if (grade5.length > 3) {
            grade55 = grade5 + total5 + ' points out of 22';
        } if (grade6.length > 3) {
            grade66 = grade6 + total6 + ' points out of 18';
        } if (grade7.length > 3) {
            grade77 = grade7 + total7 + ' points out of 28';
        } if (grade8.length > 3) {
            grade88 = grade8 + total8 + ' points out of 14';
        }
        if (grades.length == 8) {
            grade11 = grades[0] + total1 + ' points out of 38';
            grade22 = grades[1] + total2 + ' points out of 64';
            grade33 = grades[2] + total3 + ' points out of 64';
            grade44 = grades[3] + total4 + ' points out of 34';
            grade55 = grades[4] + total5 + ' points out of 22';
            grade66 = grades[5] + total6 + ' points out of 18';
            grade77 = grades[6] + total7 + ' points out of 28';
            grade88 = grades[7] + total8 + ' points out of 14';
        }
        return (
            <div>
                <img className='logo' src={Logo} />
                <div className='beg'>

                    {/* <img id='heroImg' src={HeroImg} /> */}
                    <h1>Take the test:</h1>
                    <h1> How healthy is your communications function? Take this 20-minute assessment to find out. For each statement below, rate your company as yes, somewhat or no.</h1>
                    {/* <p><b>How healthy is your communications function?</b> Take this assessment test to find out. For each statement below, rate your company as yes, somewhat or no. </p> */}
                </div>
                <form className='padding'>
                    <div class='header'>

                        <h3>Please let us know where to send your assessment score: </h3>
                        <label for="name">First Name</label>
                        <input type="text" id="name" name="name" onChange={this.handleChange} />
                        <label for="lastname">Last Name</label>
                        <input type="text" id="lastname" name="lastname" onChange={this.handleChange} />
                        <label for="title">Title</label>
                        <input type="text" id="title" name="title" onChange={this.handleChange} />
                        <label for="company">Company</label>
                        <input type="text" id="company" name="company" onChange={this.handleChange} />
                        <label for="employees">Number of Employees</label>
                        <input type="text" id="employees" name="employees" onChange={this.handleChange} />
                        <label for="email">E-mail address</label>
                        <input type="text" id="email" name="email" onChange={this.handleChange} />
                        <label for="phone">Phone</label>
                        <input type="text" id="phone" name="phone" onChange={this.handleChange} />
                    </div>
                    <div class='header'>
                        <h3>Part 1: Strategy, evaluation and accountability
                        </h3><div className='gradeSec'><h1>{grade11}</h1></div>
                    </div>
                    <label for="q1">1. We have a coherent multi-year communications strategy that directly contributes to the success of the organizational strategy.</label>
                    <button class='q' id='0q1' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='4q1' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='8q1' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q2">2. We review that strategy on a regular basis and make adjustments. </label>
                    <button class='q' id='0q2' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q2' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q2' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q3">3. We monitor the external landscape for context changes that impact our strategy.</label>
                    <button class='q' id='0q3' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q3' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q3' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q4">4. We have robust mechanisms in place to monitor the effectiveness of the strategy.</label>
                    <button class='q' id='0q4' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q4' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q4' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q5">5. We have communications performance indicators that are specific, measurable, attainable, relevant and time-bound, and the department reports on these on a regular basis (monthly or quarterly).</label>
                    <button class='q' id='0q5' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q5' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q5' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q6">6. We have coherent plans that fall from his strategy.</label>
                    <button class='q' id='0q6' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q6' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q6' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>
                    <button class='q submit' onClick={this.submit1}>Submit</button>
                    <div class='header'>
                        <h3>Part 2: Scope, role, org structure </h3>
                        <div className='gradeSec'><h1>{grade22}</h1></div>
                        <label for="q7">7. We have a communications professional that provides strategic communications guidance on the organization’s new initiatives and issues at inception. </label>
                        <button class='q' id='0q7' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q7' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q7' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>


                        <label for="q8">8. Our communications professional/team is tightly connected with marketing/fundraising/donor relations plans and their external messaging.</label>
                        <button class='q' id='0q8' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q8' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q8' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                        <label for="q9">9. Our communicator is involved at the outset of legal matters that could potentially involve internal or external reputation risks.</label>
                        <button class='q' id='0q9' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q9' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q9' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                        <label for="q10">10. Our communicator is closely collaborating with HR on staff engagement initiatives.</label>
                        <button class='q' id='0q10' onClick={this.buttonClick} onClick={this.clickFunction} onClick={this.clickFunction}>No</button><button class='q' id='1q10' onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q10' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                        <label for="q11">11.Our communicator provides strategic counsel on the development and execution of internal communications strategy and messaging.</label>
                        <button class='q' id='0q11' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q11' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q11' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                        <label for="q12">12.Our professional communicator participates in defining organizational values, principles, strategies, policies and processes. </label>
                        <button class='q' id='0q12' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q12' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q12' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>




                        <label for="q13">13. Our communicator is on top of communications trends that warrant operational changes, and their feedback is influencing those changes.</label>
                        <button class='q' id='0q13' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q13' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q13' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                        <label for="q14">14.Our communications team speedily feeds the digital/social intelligence back to company leaders and decisionmakers for possible action on opportunities and risks.</label>
                        <button class='q' id='0q14' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q14' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q14' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                        <label for="q15">15. Our communications team has metrics in place to determine their level of communication effectiveness and impact, and they adjust tactics if necessary.</label>
                        <button class='q' id='0q15' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q15' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q15' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                        <label for="q16">16. Our senior communications official reports directly to an executive who has oversight across all divisions.</label>
                        <button class='q' id='0q16' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q16' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q16' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                        <label for="q17">17. Our senior communications official is part of the senior leadership team.</label>
                        <button class='q' id='0q17' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q17' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q17' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                        <label for="q18">18. Our communications function has an appropriate budget to support best practice.</label>
                        <button class='q' id='0q18' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q18' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q18' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                        <label for="q19">19. Our organization intentionally fosters a communicative culture through its decisions and actions.</label>
                        <button class='q' id='0q19' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q19' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q19' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                        <label for="q20">20. Leaders within the company are confident, inspiring, and fearless communicators. </label>
                        <button class='q' id='0q20' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q20' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q20' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>
                        <button class='q submit' onClick={this.submit2}>Submit</button>

                        <div class='header'>
                            <h3>Part 3: The people in your communications team
</h3><div className='gradeSec'><h1>{grade33}</h1></div>
                        </div>
                        <label for="q21">21. Our chief communicator is a leader that develops and communicates a vision inside and outside of the department, motivates others to achieve the vision, focuses on strategic outcomes and takes responsibility for the performance of the team.</label>
                        <button class='q' id='0q21' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='4q21' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='8q21' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                        <label for="q22">22. Our communications team has the necessary skillsets and competencies.</label>
                        <button class='q' id='0q22' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='4q22' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='8q22' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                        <label for="q23">23. Our communications team has a professional development plan that has been implemented. </label>
                        <button class='q' id='0q23' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q23' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q23' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                        <label for="q24">24. Our communicators understand current affairs and the political context (local, regional, national, global), and the associated opportunities and risks. </label>
                        <button class='q' id='0q24' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q24' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q24' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>
                    </div>



                    <label for="q25">25.Our communications team has a succession plan. </label>
                    <button class='q' id='0q25' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q25' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q25' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>


                    <label for="q26">26. Our communications team has a process for working through underperformance. </label>
                    <button class='q' id='0q26' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q26' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q26' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>


                    <label for="q27">27. When recruiting communications staff, our communications hiring manager is present in recruitment interviews with the HR manager.</label>
                    <button class='q' id='0q27' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q27' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q27' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>


                    <label for="q28">28.Our communicators understand the business priorities and how they translate into communication strategy and planning. </label>
                    <button class='q' id='0q28' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q28' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q28' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>


                    <label for="q29">29. Our communications team plans and works with a strong stakeholder focus and clear objectives. </label>
                    <button class='q' id='0q29' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q29' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q29' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>


                    <label for="q30">30.Our communications leaders use best-practice hiring processes.</label>
                    <button class='q' id='0q30' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q30' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q30' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>


                    <label for="q31">31.Our communicators demonstrate integrity, inspiring trust, courage and honesty.</label>
                    <button class='q' id='0q31' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='4q31' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='8q31' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q32">32. Our communications department promotes equality and values diversity.</label>
                    <button class='q' id='0q32' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='4q32' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='8q32' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>
                    <button class='q submit' onClick={this.submit3}>Submit</button>

                    <div class='header'>
                        <h3>Part 4: Communicating internally
</h3><div className='gradeSec'><h1>{grade44}</h1></div>
                    </div>
                    <label for="q33">33. Our staff and leadership team are always the first to learn about significant company developments; they don’t first hear about it in external media. </label>
                    <button class='q' id='0q33' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q33' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q33' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q34">34. We are quick to set the record straight with our staff when a rumor or misinformation spreads. </label>
                    <button class='q' id='0q34' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q34' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q34' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q35">35. We have a password-protected intranet where staff can get the latest company news and information.</label>
                    <button class='q' id='0q35' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q35' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q35' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q36">36. All staff are required to visit the company intranet for the latest news on every working day.</label>
                    <button class='q' id='0q36' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q36' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q36' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q37">37. The communications department shapes internal messaging and ensures it aligns well with communication going externally. They also make sure it is true to the company brand. </label>
                    <button class='q' id='0q37' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q37' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q37' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q38">38. Our CEO and leadership team have frequent staff meetings where they are informed and motivated. This includes very transparent two-way communication among staff and the leaders.</label>
                    <button class='q' id='0q38' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='4q38' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='8q38' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>
                    <button class='q submit' onClick={this.submit4}>Submit</button>

                    <div class='header'>
                        <h3>Part 5: Brand and reputation
</h3><div className='gradeSec'><h1>{grade55}</h1></div>
                    </div>
                    <label for="q39">39. We have defined our organization’s brand that resonates with our primary stakeholders.</label>
                    <button class='q' id='0q39' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q39' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q39' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>


                    <label for="q40">40. We have a brand manual that details how the brand should be expressed in narrative and visuals.</label>
                    <button class='q' id='0q40' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q40' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q40' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q41">41. We ensure that brand policy is understood by all internal stakeholders.</label>
                    <button class='q' id='0q41' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q41' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q41' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q42">42.We have a system to monitor and enforce the brand policies.</label>
                    <button class='q' id='0q42' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q42' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q42' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q43">43.We ensure our business decisions reflect our brand. </label>
                    <button class='q' id='0q43' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q43' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q43' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q44">44. We have a communicator who challenges internal practices that can threaten our reputation or brand.</label>
                    <button class='q' id='0q44' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q44' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q44' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>



                    <label for="q45">45.We have a crisis communications plan in place, and risk communications messaging prepared ahead of time for potential issues that could create a reputation risk for us.</label>
                    <button class='q' id='0q45' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q45' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q45' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q46">46. We have a robust monitoring system that quickly identifies negative or misleading posts, and a process that allows us to quickly respond before it goes viral. </label>
                    <button class='q' id='0q46' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q46' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q46' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q47">47. Our leaders and spokespeople are media trained.</label>
                    <button class='q' id='0q47' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q47' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q47' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>
                    <button class='q submit' onClick={this.submit5}>Submit</button>
                    <div class='header'>
                        <h3>Part 6: Digital and social media
 </h3><div className='gradeSec'><h1>{grade66}</h1></div>
                    </div>
                    <label for="q48">48. We have social media policies that are clear, well-known and enforced.</label>
                    <button class='q' id='0q48' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q48' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q48' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>



                    <label for="q49">49. We provide content that is engaging and effective in achieving goals. </label>
                    <button class='q' id='0q49' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q49' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q49' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q50">50. We monitor the performance of content and adjust our content mix for constant improvement.</label>
                    <button class='q' id='0q50' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q50' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q50' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q51">51.We work with social media influencers to build audience and engagement. </label>
                    <button class='q' id='0q51' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q51' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q51' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q52">52. We track social and digital performance with a wide range of metrics that are most likely to demonstrate positive impact on business objectives.</label>
                    <button class='q' id='0q52' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q52' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q52' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>
                    <button class='q submit' onClick={this.submit6}>Submit</button>

                    <div class='header'>
                        <h3>Part 7: Communications tools and technology
</h3><div className='gradeSec'><h1>{grade77}</h1></div>
                    </div>
                    <label for="q53">53. All communicators are using the proper virus protection and security software.</label>
                    <button class='q' id='0q53' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='4q53' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='8q53' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>

                    <label for="q54">54. All communicators are properly storing and backing up their media and documents. </label>
                    <button class='q' id='0q54' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='4q54' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='8q54' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>


                    <label for="q55">55. Communicators have smartphones, laptops, cameras, and other hardware necessary for highest quality outputs.</label>
                    <button class='q' id='0q55' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q55' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q55' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>



                    <label for="q56">56. Communicators have state-of-art software (video, audio, graphic design, animation, etc.) for highest quality outputs.</label>
                    <button class='q' id='0q56' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q56' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q56' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>
                    <button class='q submit' onClick={this.submit7}>Submit</button>

                    <div class='header'>
                        <h3>Part 8: Innovation

</h3><div className='gradeSec'><h1>{grade88}</h1></div>
                    </div>

                    <label for="q57">57. The communications team has a full plan to reward, recognize and replicate innovation.</label>
                    <button class='q' id='0q57' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='3q57' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='6q57' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>



                    <label for="q58">58. The organization makes it easy for communications staff to provide ideas to management. </label>
                    <button class='q' id='0q58' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q58' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q58' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>




                    <label for="q59">59. Time is provided to research, develop, pilot and evaluate new innovations.</label>
                    <button class='q' id='0q59' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q59' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q59' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>




                    <label for="q60">60. The team pursues innovation awards.</label>
                    <button class='q' id='0q60' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q60' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q60' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>



                    <label for="q61">61. Innovation goals are part of a communicator’s performance objectives. </label>
                    <button class='q' id='0q61' onClick={this.buttonClick} onClick={this.clickFunction}>No</button><button class='q' id='1q61' onClick={this.buttonClick} onClick={this.clickFunction}>Somewhat</button><button class='q' id='2q61' onClick={this.buttonClick} onClick={this.clickFunction}>Yes</button>
                    <button class='submit myBtn' id='block' onClick={this.handleSubmit}>Submit</button>
                </form>
                <div id="myModal" class="modal">


                    <div class="modal-content">
                        <span class="close" onClick={this.span}>&times;</span>
                        <p>Congratulations! You have completed the assessment. Please check your e-mail for your overall score.
</p>
                    </div>

                </div>
            </div >
        );
    }
}