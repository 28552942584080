import React from "react"
import "../App.css"
import { Redirect } from "react-router-dom"
import axios from "axios"
import regeneratorRuntime from "regenerator-runtime"


export default class Login_Form extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            password: "",
            valid: false,
            error: null,
            success: false,
            submitted: false,
        }
    }

    handleChange = (event) => {
        event.preventDefault()
        const { name, value } = event.target
        this.setState({ [name]: value })

        let { email, password } = this.state
        if (email === "" && password === "") {
            this.setState({ valid: false })
        } else if (email !== "" && password !== "") {
            this.setState({ valid: true })
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault()

        this.setState({ submitted: true })
        let { valid } = this.state
        let { email, password } = this.state
        if (password == "dom") {
            localStorage.setItem('p', 'fjdslfjdslkfj');

            this.setState({ success: true })
        } else {

        }
        if (valid) {

            // axios call here
            // const res = await AuthService.login(email, password)

            this.setState({ success: true })
        } else {
            this.setState({ error: true })
        }
    }

    render() {
        const { valid, success, submitted } = this.state
        let popUp
        if (success) {
            return <Redirect to="/admin" />
        }
        return (
            <div className='loginForm'>
                {popUp}
                <form className="submitalForm" onSubmit={this.handleSubmit}>
                    <div className="label_input">
                        <label htmlFor="email">Name</label>
                        <input
                            type="text"
                            id="email"
                            name="email"
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="label_input">
                        <label htmlFor="password">Password</label>
                        <input
                            type="text"
                            id="password"
                            name="password"
                            onChange={this.handleChange}
                        />
                    </div>
                    <button onClick={this.handleSubmit}>Submit</button>
                </form>
            </div>
        )
    }
}