import './App.css';
import './reset.css'
import Form from './Forms/form'
import LoginForm from './Forms/LoginForm'
import AdminScreen from './Forms/AdminScreen'
import Logo from './greaterLifeRevisionBLACK.png'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
function App() {
  return (
    <Router>

      <div className="App">
        <Route exact path='/login'>
          <LoginForm />
        </Route>
        <Route exact path='/admin'>
          <AdminScreen />
        </Route>
        <Route exact path="/">
          <Form />
        </Route>

      </div>
    </Router>
  );
}

export default App;
