import React from "react"
import "../App.css"
import { Redirect } from "react-router-dom"
import { reset } from "../../node_modules/axe-core";
export default class Login_Form extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            email: "",
            password: "",
            valid: false,
            error: null,
            success: false,
            submitted: false,
            responses: [],
            u: true,
        }

    }
    fetchFun = (search) => {
        //fetch call to search return data
        fetch(search)
            .then((res) => res.text())
            .then((responses) => {
                //setData(data.message)

                responses = responses.substr(9, responses.length - 37)
                // eval(data)
                responses = responses.split("}")
                responses = responses.map((d) => d.substr(2, d.length));


                // console.log(responses)
                this.setState({ responses })
            });
    }


    render() {
        if (this.state.responses.length <= 0) {
            let d = this.fetchFun("https://by3hy8ij17.execute-api.us-west-2.amazonaws.com/Dev")
        }
        // console.log(d)
        const { responses, u } = this.state
        const c = localStorage.getItem('p');
        if (c == "fjdslfjdslkfj") {

        } else {
            this.setState({ u: false })
        }
        if (u == false) {
            return <Redirect to="/" />
        }
        // console.log(responses)
        let popUp
        const count = responses.length - 1;
        for (let i = 0; i < responses.length; i++) {
            // console.log(responses[i])
            // responses[i] = '{' + responses[i] + '}'
            // console.log(responses[0])
            // // responses[0] = JSON.parse(responses[0]);
            // console.log(responses[0])
        }
        // console.log(responses[0].total2)
        // let t = JSON.parse(responses[0]);
        const results = responses.map((d) => <li >{d}</li>);
        return (
            <div id='dfsdf'>
                <div className='r'>
                    <p id='count'>Count: {count}</p>
                    {results}
                </div>
            </div>
        )
    }
}